import {
  UploadingFile,
  DistributionPreviewData,
  ValidationTracker,
  DistributionRecord,
  DistributionRecordKey,
} from './../../../shared/models/distribution/distribution.model';
import { utils, read } from 'xlsx';
import * as EmailValidator from 'email-validator';

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from './../../../shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import {
  DistributionTemplate,
  MODULE_NAME,
  USER_PERMISSION,
} from 'src/app/shared/enum';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { ProjectHomePageMask } from 'src/app/shared/models/rig_journal/rig_journal.model';
import { DistributionService } from 'src/app/shared/services/distribution.service';
import { BehaviorSubject, of } from 'rxjs';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { AppMenuComponent } from '../app-menu/app-menu.component';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-home-distribution-list',
  templateUrl: './home-distribution-list.component.html',
  styleUrls: ['./home-distribution-list.component.scss'],
})
export class HomeDistributionListComponent
  extends BaseComponent
  implements OnChanges
{
  isLoading: boolean = false;

  @Input()
  display: boolean = false;

  // display in header of import distribution list
  @Input()
  rigName: string = '';

  @Input()
  rigProject: string = '';

  @Input()
  rigOperator: string = '';

  @Input()
  rigJournalId: string = '';

  @Input()
  rigId: string = '';

  @Input()
  distributionUrl: string = '';

  @Input()
  isChooseFileReUpload: boolean = false;

  @Input()
  reuploadStatus: number | boolean = false;

  @Input()
  uploadStatus: string = DistributionTemplate.uploadYet;

  @Input()
  language: string = '';

  @ViewChild('uploadHidden') uploadHidden: ElementRef | undefined;

  @ViewChild('importDistrbutionListButton', { read: ElementRef })
  importDistrbutionListButton: ElementRef<HTMLElement> | undefined;

  isReuploadFile: boolean = false;
  isActionOnPanel: boolean = false;

  // titleDialog: string = '';
  labelAction: string = '';

  template = DistributionTemplate;

  xlsxFile: File | null = null;
  // display progress percent
  uploadingFile: UploadingFile = {
    uploadingFileName: '',
    uploadingFileSize: 0,
    uploadedBytes: 0,
    uploadingFilePercent: 0,
    uploadingRemainingTime: 0,
  };

  // Store excel file data
  excelData: DistributionPreviewData[] = [];

  defaultOrder: string[] = [
    'PRIMARY POINTS OF CONTACT',
    'GREEN',
    'YELLOW',
    'RED',
    'BLUE',
    'GREY',
    'ORANGE',
    'PEACH',
    'MAROON',
    'BROWN',
    'CYAN',
    'PINK',
    'NAVY',
    'PURPLE',
    'LIME',
  ];

  errorSheetTab: ValidationTracker[] = [];
  onlyTabError: ValidationTracker[] = [];

  isTabError: boolean = false;

  // Check Mandatory and Optional
  mandatoryCheck = {
    phone: 'The format should follow +<Dial Code> <Phone Number>',
    phoneBlank: 'Phone is required',
    firstName: 'First name is required',
    lastName: 'Last name is required',
    name: 'First name and Last name is required',
    email: 'Email address must be valid',
    emailBlank: 'Email is required',
    order:
      'The format should follow <C><priority number> or <E><priority number>',
    orderBlank: 'Order is required',
  };

  optionalCheck = {
    name: 'First name and Last name are recommended',
    firstName: 'First name is recommended',
    lastName: 'Last name is recommended',
    businessUnit: 'Business unit is recommended', // currently not used
    halId: 'HAL ID is recommneded if the recipient is a Halliburton employee', // currently not used
    title: 'Title is recommended',
    phone: 'The format should follow +<Dial Code> <Phone Number>',
    phoneBlank: 'phone is recommended',
  };

  checkFileMessage: string = '';

  fileFetching: File | undefined;

  @ViewChild('menu') menu: AppMenuComponent | undefined;
  getMenuItemsForItem$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<
    MenuItem[]
  >([]);

  // Checking User Permissions
  isViewer: boolean = false;
  isEngineer: boolean = false;

  canEdit: boolean = false;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private homeService: HomeService,
    private notificationService: NotificationService,
    public blobService: AzureBlobService,
    private userInfoService: UserInfoService,
    private distributionService: DistributionService,
    private confirmService: ConfirmDialogService
  ) {
    super();
  }
  onInit(): void {
    this.userInfoService.userSubject$.subscribe(currentUser => {
      if (currentUser && currentUser.id && currentUser.role) {
        this.isViewer = currentUser.role === AppConstant.ROLES.VIEWER.label;
        this.isEngineer = currentUser.role === AppConstant.ROLES.ENGINEER.label;
      }
      this.canEdit = this.userInfoService.hasPermission(
        MODULE_NAME.RIG_TILE,
        USER_PERMISSION.EDIT
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reuploadStatus?.currentValue && this.uploadHidden) {
      this.uploadHidden.nativeElement.click();
    }

    if (changes.distributionUrl?.currentValue) {
      this.loadDisData(changes.distributionUrl?.currentValue || '');
    }
  }

  loadDisData(distributionUrl: string) {
    if (distributionUrl) {
      this.isChooseFileReUpload = false;
      this.uploadingFile.uploadingFileName =
        AppHelper.UtileFunctions.getFileNameFromUrl(distributionUrl);

      this.blobService
        .getFileByUrl(
            distributionUrl
        )
        .subscribe(
          {
            next: (distributionFile: Blob) => {
              if (distributionFile) {
                this.fileFetching = distributionFile as File;
                this.parseXLSXFile(this.fileFetching);
              }
          } ,
          error: (error) => {
            // Handle errors that occurred during document reading
          console.error('An error occurred:', error?.message);
          this.confirmService.messageErrorDialog(
            'An error occurred: ' + error?.message
          );

          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Read XLSX Document',
            content: "Can't find the document in the database",
          });

          if (error.statusCode === 404) {
            console.error('The specified document does not exist.');
            this.confirmService.messageErrorDialog(
              'The specified document does not exist.'
            );

            // Call api to remove error distributionUrl
            if (this.rigJournalId) {
              this.homeService
                .removeDistributionList(this.rigJournalId)
                .subscribe({
                  next: () => {
                    this.distributionService.setDisplay({
                      render: {
                        isShow: true,
                        template: DistributionTemplate.uploadYet,
                      },
                    });

                    this.distributionService.setFocusRigCard({
                      rigJournalId: this.rigJournalId,
                      distributionListUrl: '',
                      updateType: 'Distribution',
                    });

                    this.isActionOnPanel = false;
                  },
                  error: () => {
                    this.notificationService.setMessage({
                      type: AppConstant.MESSAGE_TYPE.WARNING,
                      header: 'Update Distribution List Failure',
                      content: error,
                    });
                    this.closePanel();
                  },
                });
            } else {
              this.notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'RigJournalId Not Found',
                content:
                  'Please check again RigJournalId: ' + this.rigJournalId,
              });
              this.closePanel();
            }
          } else {
            this.distributionService.setDisplay({
              render: {
                isShow: true,
                template: DistributionTemplate.errorLoading,
              },
            });
          }
        }
      })
    }
  }

  onDestroy(): void {}

  getNameUploadFileBydistributionUrl(distributionUrl: string) {
    let splitArr = distributionUrl.split('/');
    return splitArr[splitArr.length - 1];
  }

  closePanel() {
    if (this.uploadStatus === this.template.uploading) return;
    // Have Action Panel --> Ask for close
    if (this.isActionOnPanel) {
      this.confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });
    } else {
      this.hidePanel();
    }
  }

  hidePanel() {
    this.isTabError = false;
    this.distributionService.setDisplay({
      render: {
        isShow: false,
        template: DistributionTemplate.uploadYet,
      },
    });
  }

  showContextMenu(event: MouseEvent, inputData: string) {
    this.getMenuItemsForItem$.next(this.getMenuFileDistribution(inputData));
    this.menu!.toggle(event);
  }
  // MenuItem[]
  getMenuFileDistribution(distributionUrl: string): MenuItem[] {
    const disabledClass =
      distributionUrl || !this.isChooseFileReUpload ? '' : 'p-disabled';

    if (!this.canEdit && !this.isViewer) return [];

    return [
      {
        label: 'Download',
        icon: 'c-icons export-icon',
        styleClass: disabledClass,
        disabled: this.isViewer,
        command: () => {
          if (!this.fileFetching) {
            this.notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Download Distribution Failed',
              content: 'Missing File Download!',
            });
            return;
          }
          this.downLoadFileDistribution(distributionUrl, this.fileFetching);
        },
      },
      {
        label: 'Re-Upload',
        icon: 'c-icons reup-icon',
        disabled: this.isViewer,
        command: () => {
          this.dialogReupload();
        },
      },
      {
        label: 'Delete',
        icon: 'c-icons trash-bin-icon',
        styleClass: 'red-label',
        disabled: this.isViewer,
        command: () => {
          this.labelAction = 'Delete';

          this.confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: `Are you sure you want to delete this distribution list (${this.uploadingFile?.uploadingFileName}) from WellCare?`,
            haveCheckbox: true,
            checkboxLabel: 'Yes, I want to delete distribution list.',
            haveDialogMessage: false,
            havePrimaryButton: true,
            primaryButtonLabel: this.labelAction,
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            haveSecondaryButton: true,
            secondaryButtonLabel: 'Cancel',
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'Delete'),
          });
        },
      },
    ];
  }

  dialogReupload() {
    this.labelAction = 'Re-upload';

    this.confirmService.setDialog({
      ...this.confirmDialog,
      isVisible: true,
      header: 'Confirm Re-Upload',
      haveCheckbox: true,
      checkboxLabel: 'Are you sure to re-upload this Distribution List?',
      haveDialogMessage: false,
      havePrimaryButton: true,
      primaryButtonLabel: this.labelAction,
      isValidPrimaryButton: true,
      disablePrimaryButton: false,
      haveSecondaryButton: true,
      secondaryButtonLabel: 'Cancel',
      buttonEvent: (event: OptionButtonType) =>
        this.onButtonClickDialog(event, 'Re-upload'),
    });
  }
  // download distribution list
  downLoadFileDistribution(distributionUrl: string, fileXLSX: File) {
    let fileName = decodeURIComponent(
      AppHelper.StringFunctions.getFileName(
        this.getNameUploadFileBydistributionUrl(distributionUrl)
      )
    );
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(fileXLSX);
    link.download = fileName;
    link.click();
  }
  // copy text
  copyText(copyText: string) {
    navigator.clipboard.writeText(copyText);
    this.notificationService.setMessage({
      type: AppConstant.MESSAGE_TYPE.SUCCESS,
      header: 'Copy clipboard !',
      content: `Copied: ${copyText} successfully!`,
    });
  }

  // import distribution button
  openImportDistrbutionList() {
    if (this.isViewer || !this.canEdit) return;
    if (this.uploadHidden) this.uploadHidden.nativeElement.click();
  }

  importDistrbutionList(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    if (this.isViewer || !this.canEdit) return;

    const uploadType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const uploadExt = 'xlsx';

    // Check file existed
    if (inputElement.files && inputElement.files.length) {
      const fileUpload = inputElement.files[0];
      this.isChooseFileReUpload = true;
      this.distributionUrl = '';

      if (
        fileUpload.type === uploadType &&
        fileUpload.name.slice(-4) === uploadExt
      ) {
        this.isActionOnPanel = true;
        this.parseXLSXFile(fileUpload);
      } else {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid File Type',
          content: 'Allowed only file types: .xlsx',
        });
      }
    } else {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Invalid File Type',
        content: 'Upload file does not exist',
      });
    }
  }

  parseXLSXFile(fileUpload: File) {
    this.distributionService.setDisplay({
      render: {
        isShow: true,
        template: DistributionTemplate.uploading,
      },
    });

    if (fileUpload.name) {
      this.uploadingFile.uploadingFileName = fileUpload.name;
    }

    this.uploadingFile.uploadingFileSize = fileUpload.size;
    this.uploadingFile.uploadedBytes = 0;
    this.uploadingFile.uploadingFilePercent = 0;

    let timeStarted = new Date();
    let interval = setInterval(
      () => {
        let ramdomNum = Math.floor(
          AppHelper.MathFunctions.getRandomNumber() * (55 - 25) + 25
        );

        this.uploadingFile.uploadingFilePercent =
          this.uploadingFile.uploadingFilePercent + ramdomNum;
        if (this.uploadingFile.uploadingFilePercent < 100) {
          this.uploadingFile.uploadedBytes =
            (Math.floor(this.uploadingFile.uploadingFileSize) / 100) *
            this.uploadingFile.uploadingFilePercent;
        }
        if (this.uploadingFile.uploadingFilePercent >= 100) {
          this.uploadingFile.uploadingFilePercent = 100;
          this.uploadingFile.uploadedBytes =
            (Math.floor(this.uploadingFile.uploadingFileSize) / 100) *
            this.uploadingFile.uploadingFilePercent;

          clearInterval(interval);

          this.displayXLSXFileTable(fileUpload);
        }
      },
      this.uploadingFile.uploadingFileSize > 100000 ? 1000 : 700
    );

    let timeInterval = setInterval(() => {
      let timeElapsed = new Date().valueOf() - timeStarted.valueOf();
      let uploadSpeed = this.uploadingFile.uploadedBytes / (timeElapsed / 1000);

      this.showRemainTime(
        (this.uploadingFile.uploadingFileSize -
          this.uploadingFile.uploadedBytes) /
          uploadSpeed
      );

      if (
        this.uploadingFile.uploadedBytes >= this.uploadingFile.uploadingFileSize
      ) {
        clearInterval(timeInterval);
      }
    }, 1000);
  }
  showRemainTime(number: number) {
    return (this.uploadingFile.uploadingRemainingTime = number);
  }

  displayXLSXFileTable(fileUpload: File) {
    try {
      this.readFileExcel(fileUpload);
      this.isLoading = false;
    } catch (e) {
      this.distributionService.setDisplay({
        render: {
          isShow: true,
          template: DistributionTemplate.errorLoading,
        },
      });

      this.isLoading = false;
    }
  }

  readFileExcel(fileUpload: File) {
    this.excelData = [];
    let file = fileUpload;
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onload = () => {
      let workBook = read(fileReader.result, { type: 'binary' });
      let sheetNames = workBook.SheetNames;

      let previewSheet: string[] = [];
      let previewSheetSorted: string[] = [];

      // Đọc sheettab input và biến chugs thành kiểu chung UPPER
      // sheetNames = sheetNames.map((item) => item.trim().toUpperCase());
      // Lọc ra các file có trong DefaultOrder
      previewSheet = sheetNames.filter(item =>
        this.defaultOrder.includes(item.trim().toLocaleUpperCase())
      );

      // Nếu còn tồn tại bất kì sheettab nào hợp lệ thì cần sort chúng lại trước khi đọc file để render
      // Nếu không thì out ra

      // Check tabs and first tab.
      if (previewSheet.length && previewSheet[0] === this.defaultOrder[0]) {
        this.defaultOrder.filter(item => {
          let findItem = previewSheet.find(el => {
            return el.trim().toLocaleUpperCase() === item;
          });
          findItem && previewSheetSorted.push(findItem);
          return findItem;
        });
      } else {
        this.invalidDocument(
          'Distribution list needs to have Primary Points Of Contact tab.'
        );
        return;
      }

      // Check first tab dont have any records
      if (
        utils.sheet_to_json(workBook.Sheets[previewSheetSorted[0]])
          .length == 0
      ) {
        this.invalidDocument('Distribution list needs to have records');
        return;
      }

      for (let index = 0; index < workBook.SheetNames.length; index++) {
        const sheetName = workBook.SheetNames[index];
        // Get worksheet
        const worksheet = workBook.Sheets[sheetName];
        // Convert worksheet to array of objects
        const data = utils.sheet_to_json(worksheet, { header: 1 });
        // Get list of columns from the first row
        const columns: any = data.length > 0 ? data[0] : [];

        let allowedKeys: (keyof DistributionRecordKey)[];
        if (sheetName === 'PRIMARY POINTS OF CONTACT') {
          allowedKeys = [ 'FIRST NAME', 'LAST NAME', 'BUSINESS UNIT', 'HAL ID', 'TITLE', 'PHONE', 'EMAIL', 'ORDER' ];
        } else {
          allowedKeys = [ 'FIRST NAME', 'LAST NAME', 'BUSINESS UNIT', 'HAL ID', 'TITLE', 'PHONE', 'EMAIL' ];
        }

        // Check strictly default columns
        const isMissingColumn = allowedKeys.every(key => columns.includes(key));
        if (!isMissingColumn) {
          this.invalidDocument(
            `${sheetName} Tab currently does not have enough default columns according to the default template, please check again`
          );
          break;
        }
      }

      // for (let index = 0; index < workBook.SheetNames.length; index++) {
      //   const sheetName = workBook.SheetNames[index];
      //   const worksheet = workBook.Sheets[sheetName];
      //   const data: any = XLSX.utils.sheet_to_json(worksheet);

      //   const columns:any = [];
      //   for (let key in data[0]) {
      //     columns.push(key);
      //   }

      //   let allowedKeys: (keyof DistributionRecordKey)[];
      //   if (sheetName === 'PRIMARY POINTS OF CONTACT') {
      //     allowedKeys = [ 'FIRST NAME', 'LAST NAME', 'BUSINESS UNIT', 'HAL ID', 'TITLE', 'PHONE', 'EMAIL', 'ORDER' ];
      //   } else {
      //     allowedKeys = [ 'FIRST NAME', 'LAST NAME', 'BUSINESS UNIT', 'HAL ID', 'TITLE', 'PHONE', 'EMAIL' ];
      //   }

      //   // Remove useless columns
      //   // for (let key in columns) {
      //   //   if (!allowedKeys.includes(key as keyof DistributionRecordKey)) {
      //   //     delete columns[key as keyof DistributionRecordKey];
      //   //   }
      //   // }

      //   // Check strictly default columns
      //   const isMissingColumn = allowedKeys.every(key => columns.includes(key))
      //   if(!isMissingColumn) {
      //     this.invalidDocument(
      //       `${sheetName} Tab currently does not have enough default columns according to the default template, please check again`
      //     );
      //     break;
      //   }
      //   // console.log(`Columns for ${sheetName}:`, columns);
      // };

      // Biến để lưu tab nào có lỗi
      this.errorSheetTab = [];
      this.onlyTabError = [];
      previewSheetSorted.forEach(el => {
        let errorEl: ValidationTracker = {
          name: el,
          isError: false,
          isWarning: false,
        };
        this.errorSheetTab.push(errorEl);
      });

      const regexPhone: RegExp = new RegExp(
        /^\+(1|[1-9][0-9]{0,2}) [1-9][0-9]{5,11}$/
      );
      const regexHyphen: RegExp = new RegExp(/--+/);

      for (let i = 0; i < previewSheetSorted.length; i++) {
        const rawItem: DistributionRecord[] = utils.sheet_to_json(
          workBook.Sheets[previewSheetSorted[i]]
        );

        let isError = false;
        let isWarning = false;
        rawItem.forEach((item: DistributionRecord) => {
          item.VALIDATION_CHECK = {
            MANDATORY: {
              EMAIL_ERROR: false,
              PHONE_ERROR: false,
              ORDER_ERROR: false,
              PRI_FIRSTNAME_ERROR: false,
              PRI_LASTNAME_ERROR: false,
            },
            OPTIONAL: {
              PHONE_ERROR: false,
              FIRSTNAME_ERROR: false,
              LASTNAME_ERROR: false,
              BUSINESS_UNIT_ERROR: false,
              HALL_ID_ERROR: false,
              TITLE_ERROR: false,
            },
          };

          /* Check Group Mandatory */

          // Check Email valid
          if (
            !item.EMAIL ||
            !EmailValidator.validate(String(item.EMAIL).trim())
          ) {
            this.errorSheetTab[i].isError = true;
            item.VALIDATION_CHECK.MANDATORY.EMAIL_ERROR = true;
            isError = true;
          }

          // Check Order Valid -- only first tab
          if (
            previewSheetSorted[i] === this.defaultOrder[0] &&
            (!item.ORDER || !this.checkOrderValue(item.ORDER))
          ) {
            this.errorSheetTab[i].isError = true;
            item.VALIDATION_CHECK.MANDATORY.ORDER_ERROR = true;
            isError = true;
          }

          // Check Phone valid
          item.PHONE = AppHelper.UtileFunctions.replaceAllCharacter(
            item.PHONE ?? '',
            '-',
            ''
          );

          // Check Phone in Primary Sheet Tab

          const inputValue = item.PHONE ?? '';
          const trimmedValue = String(inputValue).trim();
          if (previewSheetSorted[i] === this.defaultOrder[0]) {
            if (
              !item.PHONE ||
              !(
                !regexHyphen.test(item.PHONE) &&
                regexPhone.test(trimmedValue.replace(new RegExp('-', 'g'), ''))
              )
            ) {
              this.errorSheetTab[i].isError = true;
              item.VALIDATION_CHECK.MANDATORY.PHONE_ERROR = true;
              isError = true;
            }
          } else {
            if (
              item.PHONE &&
              !(
                !regexHyphen.test(item.PHONE) &&
                regexPhone.test(trimmedValue.replace(new RegExp('-', 'g'), ''))
              )
            ) {
              this.errorSheetTab[i].isWarning = true;
              item.VALIDATION_CHECK.OPTIONAL.PHONE_ERROR = true;
              isWarning = true;
            }
          }

          /* Check Group Optional */

          // Special Case check
          // Check Phone in other sheet - Optionally check

          // Special Case check
          // Check  First Name + Last Name in Primary Sheet Tab
          if (
            previewSheetSorted[i] === this.defaultOrder[0] &&
            !item['FIRST NAME']
          ) {
            this.errorSheetTab[i].isError = true;
            item.VALIDATION_CHECK.MANDATORY.PRI_FIRSTNAME_ERROR = true;
            isError = true;
          }

          if (
            previewSheetSorted[i] === this.defaultOrder[0] &&
            !item['LAST NAME']
          ) {
            this.errorSheetTab[i].isError = true;
            item.VALIDATION_CHECK.MANDATORY.PRI_LASTNAME_ERROR = true;
            isError = true;
          }

          // Check First Name
          if (!item['FIRST NAME']) {
            this.errorSheetTab[i].isWarning = true;
            item.VALIDATION_CHECK.OPTIONAL.FIRSTNAME_ERROR = true;
            isWarning = true;
          }

          // Check Last Name
          if (!item['LAST NAME']) {
            this.errorSheetTab[i].isWarning = true;
            item.VALIDATION_CHECK.OPTIONAL.LASTNAME_ERROR = true;
            isWarning = true;
          }

          // Check Company - HAL ID
          if (item['HAL ID']) {
            item.COMPANY = 'Halliburton';
          } else {
            item.COMPANY = 'External';
            this.errorSheetTab[i].isWarning = true;
            item.VALIDATION_CHECK.OPTIONAL.HALL_ID_ERROR = true;
            isWarning = true;
          }

          // Check Company - TITLE
          if (!item['TITLE']) {
            this.errorSheetTab[i].isWarning = true;
            item.VALIDATION_CHECK.OPTIONAL.TITLE_ERROR = true;
            isWarning = true;
          }

          // Assign Value for Name Hover
          if (previewSheetSorted[i] === this.defaultOrder[0]) {
            // First Name and First Name handle Hover Text
            if (item.VALIDATION_CHECK.MANDATORY.PRI_FIRSTNAME_ERROR) {
              item.NAME_HOVER = this.mandatoryCheck.firstName;
            } else if (item.VALIDATION_CHECK.MANDATORY.PRI_LASTNAME_ERROR) {
              item.NAME_HOVER = this.mandatoryCheck.lastName;
            } else {
              item.NAME_HOVER = item['FIRST NAME'] + ' ' + item['LAST NAME'];
            }

            // Phone Number handle Hover Text
            if (item.VALIDATION_CHECK.MANDATORY.PHONE_ERROR) {
              if (!item.PHONE) {
                item.PHONE_HOVER = this.mandatoryCheck.phoneBlank;
              } else {
                item.PHONE_HOVER = this.mandatoryCheck.phone;
              }
            } else {
              item.PHONE_HOVER = item.PHONE;
            }
          } else {
            // First Name and First Name handle Hover Text
            if (item.VALIDATION_CHECK.OPTIONAL.FIRSTNAME_ERROR) {
              item.NAME_HOVER = this.optionalCheck.firstName;
            } else if (item.VALIDATION_CHECK.OPTIONAL.LASTNAME_ERROR) {
              item.NAME_HOVER = this.optionalCheck.lastName;
            } else {
              item.NAME_HOVER = item['FIRST NAME'] + ' ' + item['LAST NAME'];
            }

            // Phone Number handle Hover Text
            if (item.VALIDATION_CHECK.OPTIONAL.PHONE_ERROR) {
              if (!item.PHONE) {
                item.PHONE_HOVER = this.optionalCheck.phoneBlank;
              } else {
                item.PHONE_HOVER = this.optionalCheck.phone;
              }
            } else {
              item.PHONE_HOVER = item.PHONE;
            }
          }
        });

        const firstSheetName = workBook.Sheets[previewSheetSorted[i]];
        const addressOfCell = 'K1';
        const purposeCell = firstSheetName[addressOfCell];
        const purposeValue: string = purposeCell
          ? purposeCell.v
          : previewSheetSorted[i];

        if (rawItem.length) {
          const data = rawItem as DistributionRecord[];
          const previewDataItem: DistributionPreviewData = {
            sheetName: previewSheetSorted[i],
            purpose: purposeValue,
            data,
            isError,
            isWarning,
          };
          this.excelData.push(previewDataItem);
        }
      }

      if (this.excelData.length) {
        this.xlsxFile = fileUpload;

        // Update status of Excel Uploaded (isError)
        this.checkInvalidTab();

        this.distributionService.setDisplay({
          render: {
            isShow: true,
            template: DistributionTemplate.uploaded,
          },
        });
      } else {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'File Distribution List Invalid',
          content:
            'Your distribution list file does not exist value, please check ORDER again',
        });

        this.distributionService.setDisplay({
          render: {
            isShow: true,
            template: DistributionTemplate.uploadYet,
          },
        });
      }
    };
  }

  checkInvalidTab() {
    // Check Invalid Tab Status
    this.onlyTabError = [];
    for (let index = 0; index < this.errorSheetTab.length; index++) {
      if (this.errorSheetTab[index].isError) {
        this.isTabError = true;
        this.onlyTabError.push(this.errorSheetTab[index]);
      }
    }

    if (this.onlyTabError.length) {
      let tabs = this.onlyTabError.map(tab => `"${tab.name}"`).join(', ');
      this.checkFileMessage = `The file contains invalid data in cells with ${tabs} alerts. Please correct the data and re-upload the file.`;
    }
  }

  saveDialog() {
    this.isReuploadFile = false;
    this.checkInvalidTab();
    if (this.isTabError) {
      let tabError = this.onlyTabError.map((tab: ValidationTracker) => {
        if (tab.name === 'PRIMARY POINTS OF CONTACT') {
          return 'Primary Points of Contact';
        } else {
          return (
            tab.name.charAt(0).toUpperCase() + tab.name.slice(1).toLowerCase()
          );
        }
      });

      let dialogMessage = `Detected invalid data in ${this.onlyTabError.length > 1 ? 'tabs' : 'tab'}: ${tabError.join(', ')}`;

      this.confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Information',
        haveDialogMessage: true,
        dialogMessage: dialogMessage,
        havePrimaryButton: true,
        haveSecondaryMessage: true,
        secondaryMessage: 'Could you review and update again?',
        primaryButtonLabel: 'Cancel',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: false,
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Save'),
      });
    } else {
      this.isTabError = false;
      this.saveDistributionList(this.xlsxFile, this.rigJournalId, this.language);
    }
  }

  saveDistributionList(xlsxFile: File | null, rigJournalId: string, language: string) {
    this.isLoading = true;

    if (xlsxFile) {
      try {
        this.homeService
          .addDistributionList(xlsxFile, rigJournalId, language)
          .subscribe(({ data }) => {
            const response: ProjectHomePageMask = data as ProjectHomePageMask;
            this.isActionOnPanel = false;

            if (response.distributionListUrl)
              this.distributionUrl = response.distributionListUrl;

            this.isChooseFileReUpload = false;
            this.fileFetching = xlsxFile;
            this.notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Add Distribution List',
              content: 'Distribution was added successfully!',
            });

            this.distributionService.setFocusRigCard({
              rigJournalId: this.rigJournalId,
              distributionListUrl: response.distributionListUrl,
              updateType: 'Distribution',
            });

            this.isLoading = false;
          });
      } catch (error) {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Add Distribution List Failed!',
          content:
            'Error occured while posting uploaded file. See below message for details : ' +
            error,
        });
        this.isLoading = false;
      }
    } else {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Download Distribution Failed',
        content: 'Missing File Download!',
      });
    }
  }

  invalidDocument(details: string = '') {
    this.distributionService.setDisplay({
      render: {
        isShow: true,
        template: DistributionTemplate.uploadYet,
      },
    });

    this.notificationService.setMessage({
      type: AppConstant.MESSAGE_TYPE.WARNING,
      header: 'Detected: Invalid file format',
      content: `Only allow format of Wellcare Halliburton. ${details}`,
    });
    return;
  }

  changeDistributionListDialog(distributionUrl: string, action: string) {
    if (action === 'Delete')
      !distributionUrl
        ? this.deleteTempDistributionList()
        : this.deleteDistributionList();
    else this.reUploadDistributionList();
  }

  deleteTempDistributionList() {
    this.confirmService.clearDialog();

    this.onlyTabError = [];
    this.isTabError = false;

    this.isActionOnPanel = true;
    this.xlsxFile = null;

    this.distributionService.setDisplay({
      render: {
        isShow: true,
        template: DistributionTemplate.uploadYet,
      },
    });
  }
  deleteDistributionList() {
    this.confirmService.clearDialog();

    this.isLoading = true;
    try {
      this.homeService
        .removeDistributionList(this.rigJournalId)
        .subscribe(() => {
          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Delete Distribution',
            content: 'Delete Distribution list successfully!',
          });
          this.isChooseFileReUpload = false;
          this.distributionUrl = '';
          this.isActionOnPanel = false;

          this.distributionService.setDisplay({
            render: {
              isShow: true,
              template: DistributionTemplate.uploadYet,
            },
          });

          this.distributionService.setFocusRigCard({
            rigJournalId: this.rigJournalId,
            distributionListUrl: '',
            updateType: 'Distribution',
          });

          this.isLoading = false;
        });
    } catch (error) {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Update Distribution',
        content: `Warning occured while remove uploaded file: ${error}`,
      });
      this.isLoading = false;
    }
  }

  reUploadDistributionList() {
    this.confirmService.clearDialog();

    this.isTabError = false;
    this.isReuploadFile = true;
    this.isActionOnPanel = true;
    this.isChooseFileReUpload = false;

    this.distributionService.setDisplay({
      render: {
        isShow: true,
        template: DistributionTemplate.uploadYetReload,
        // reuploadStatus: AppHelper.MathFunctions.getRandomNumber(),
      },
    });
  }

  checkOrderValue(orderValue: string) {
    var pattern = /^[EC]\d+/;
    return pattern.test(orderValue);
  }

  onButtonClickDialog(
    option: OptionButtonType,
    dialogType: DialogAction
  ): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.hidePanel();
            this.confirmService.clearDialog();
            break;
          case 'Delete':
          case 'Re-upload':
            // Because it depend in this.labelAction
            this.changeDistributionListDialog(
              this.distributionUrl,
              this.labelAction
            );
            break;
          default:
            this.confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this.confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this.confirmService.clearDialog();
        break;
      default:
        break;
    }
  }
}
