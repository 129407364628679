import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AzureAdService } from './shared/services/azure-ad.service';
import { GraphApiService } from './shared/services/graph-api.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
// import { HomeModule } from './pages/home/home.module';
// import { InterventionModule } from './pages/intervention/intervention.module';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { LoggingInterceptor } from './shared/interceptor/logging-Interceptor';
import { ErrorHandler, NgModule } from '@angular/core';
import { NotificationModule } from './pages/notification/notification.module';
import { ResponseInterceptor } from './shared/interceptor/response.interceptor';
import { ShareModule } from './shared/shared.module';
import { TestModule } from './pages/test/test.module';
import { environment } from 'src/environments/environment';
// import { UserModule } from './pages/user/user.module';
// import { RigModule } from './pages/rig/rig.module.backup';
// import { HelpVideoModule } from './pages/help-center/help-center.module';
// import { MailBoxModule } from './pages/mailbox/mailbox.module';
// import { AlertModule } from './pages/alert/alert.module.backup';
import { ApplicationInsightsService } from './shared/services/telemetry.service';
import { LibModule } from 'src/lib/lib.module';
import { GlobalErrorHandler } from './shared/services/globalErrorHandler.service';
import { RequestInterceptor } from './shared/interceptor/request.interceptor';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

const declarations = [AppComponent, LayoutComponent];
const imports = [
  BrowserModule,
  AppRoutingModule,
  // LoginModule,
  TestModule,
  // HomeModule,
  // UserModule,
  // RigModule,
  // MailBoxModule,
  // HelpVideoModule,
  // AlertModule,
  ShareModule,
  NotificationModule,
  // InterventionModule,
  HttpClientModule,
  BrowserAnimationsModule,

  MsalModule.forRoot(
    new PublicClientApplication({
      auth: {
        clientId: 'f635d4ad-70b1-4e9c-a7ef-a6cf0449b9b1', // Application (client) ID from the app registration
        authority:
          'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        redirectUri: environment.redirectUri, // This is your redirect URI
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      },
    }),
    {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [`${environment.scopes}`],
      },
    },
    {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        [environment.apiUrl, [environment.scopes]],
        ['https://graph.microsoft.com/v1.0/me', [`${environment.scopes}`]],
      ]),
    }
  ),
  LibModule,
];

export function MSALInstanceFactory(): IPublicClientApplication {
  console.log(environment);
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: 'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5',
      redirectUri: environment.redirectUri,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
    
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [environment.scopes]);
  protectedResourceMap.set(environment.apiUrl, [environment.scopes]);
  protectedResourceMap.set('/api/', [environment.scopes]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.scopes],
    },
  };
}


@NgModule({
  declarations: [...declarations],
  imports: [...imports],
  providers: [
    ApplicationInsightsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    MsalGuard,
    AzureAdService,
    GraphApiService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
