import { from, interval, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import axios from 'axios';
import { AppConstant } from '../utilities/app.constant';
import { UserInfoService } from './user-info.service';

@Injectable({
  providedIn: 'root',
})
export class GraphApiService {
  GRAPH_SCOPE = 'https://graph.microsoft.com/.default';

  // 60 seconds for refresh token
  TIMER_REFRESH = 60000;
  accessToken = '';
  private intervalSubscription: Subscription;

  constructor(
    private _msalService: MsalService,
    private _userInfoService: UserInfoService,
  ) {
    this.intervalSubscription = this.setInterval().subscribe();
  }

  private setInterval(): Observable<any> {
    return interval(this.TIMER_REFRESH).pipe(
      tap(() => this.accessToken = '')
    )
  }

  ngOnDestroy() {
    // Clean up the subscription to prevent memory leaks
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  private getTokenForScope(): Observable<any> {
    const accessTokenRequest = {
      scopes: [this.GRAPH_SCOPE]
    };
    const accessToken = this.accessToken;

    if (!accessToken) {
      return from(this._msalService.acquireTokenSilent(accessTokenRequest).pipe(
        tap((result) => this.accessToken = result.accessToken),
        map(result => result.accessToken)
      ));
    } else {
      return of(accessToken)
    }
  }

  public getListData(): Observable<any> {
    return this.getTokenForScope().pipe(
      switchMap((accessToken) => {
        return Promise.all([
          this.getListChats(accessToken),
          this.getListChannel(accessToken)
        ]).then(([chats, channels]) => ({
          chats,
          channels
        }));
      }
    ))
  }

  private async getListChats(accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const urlQuery = 'https://graph.microsoft.com/v1.0/me/chats?$filter=chatType eq \'group\' and topic ne null&$top=50';
    let nextLink = '';
    let groupResponse: any[] = [];

    do {
      const response = await axios.get(nextLink || urlQuery,
        { headers }
      );
      const listChat = response?.data?.value;
      groupResponse = groupResponse.concat(listChat.map((group: any) => ({
        id: group.id,
        groupId: group.id,
        label: group.topic
      })));
      nextLink = response?.data?.['@odata.nextLink'] || null;
    } while (nextLink);

    // validate user are in this list group, remove group if use are not in
    const listChatId = groupResponse.map((group: any) => (group.id));
    const promiseAll: Promise<any>[] = []
    listChatId.forEach(async (id: string) => {
      promiseAll.push(this.checkGroupChatExist(accessToken, id))
    })
    const responsePromise = await Promise.all(promiseAll);
    const result: any = {};
    responsePromise.forEach((item: any) => {
      result[item.id] = item;
    })
    groupResponse = groupResponse.filter((group: any) => result?.[`${group.id}`].status == AppConstant.STATUS_CONNECTION_MSTEAMS.CONNECTED)
    
    return groupResponse;
  }

  private async getListChannel(accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const teamsResponse = await axios.get(
      'https://graph.microsoft.com/v1.0/me/joinedTeams',
      { headers }
    );

    if (!teamsResponse?.data?.value) return [];

    const teams = teamsResponse.data.value;
    const promiseAllChannel: Promise<any>[] = [];
    const teamResponse: any[] = [];
    for (const team of teams) {
      const channelsResponse = axios.get(
        `https://graph.microsoft.com/v1.0/teams/${team.id}/channels`,
        { headers }
      );
      promiseAllChannel.push(channelsResponse);
      teamResponse.push({
        id: team.id,
        label: team.displayName,
        children: []
      })
    }

    const response = await Promise.all(promiseAllChannel);
    response.forEach((r: any) => {
      const listChannel = r.data.value.filter((channel: any) => channel?.webUrl);
      if (listChannel.length <= 0) return;

      const url = new URL(listChannel[0].webUrl);
      const urlParams = new URLSearchParams(url.search);
      const teamId = urlParams.get('groupId');

      listChannel.forEach((channel: any) => {
        const team = teamResponse.find((team: any) => team.id == teamId);
        team.children.push({
          id: channel.id,
          teamId: teamId,
          label: channel.displayName
        })
      })
    })

    return teamResponse;
  }

  public checkListGroupChatExist(chatIds: string[] = []): Observable<any> {
    if (chatIds.length == 0) return of([]);

    return this.getTokenForScope().pipe(
      switchMap((accessToken) => {
        const promiseAll: Promise<any>[] = []
        chatIds.forEach(async (id: string) => {
          promiseAll.push(this.checkGroupChatExist(accessToken, id))
        })
        return Promise.all(promiseAll).then((res) => {
          const result: any = {};
          res.forEach((item) => {
            result[item.id] = item;
          })
          return result;
        });
      }
    ))
  }

  private async checkGroupChatExist(accessToken: string, chatId: string): Promise<any> {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    
    try {
      const userId = this._userInfoService.userSubject.getValue().id;
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/me/chats/${chatId}/members`,
        { headers }
      );
  
      if (response?.data?.error?.code == 'Forbidden')
        return {id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER}
        
      if (response?.data?.value) {
        const isExist = response.data.value.find((user:any) => user.userId == userId);
        if (isExist) {
          return {id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.CONNECTED}
        } else {
          return {id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER}
        }
      }
  
      return { id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT };
    } catch (e: any) {
      console.log(e);
      if (e?.response?.data?.error.code == 'Forbidden')
        return {id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER}

      return { id: chatId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT };
    }
  }

  public checklistTeamExist(teams: any[]): Observable<any> {
    if (teams.length == 0) return of([]);

    return this.getTokenForScope().pipe(
      switchMap((accessToken) => {
        const promiseAll: Promise<any>[] = []
        teams.forEach(async (team: any) => {
          promiseAll.push(this.checkTeamsExist(accessToken, team))
        })
        return Promise.all(promiseAll).then((res) => {
          const result: any = {};
          res.forEach((item) => {
            result[item.id] = item;
          })
          return result;
        });
      }
    ))
  }

  
  private async checkTeamsExist(accessToken: string, team: any) {
    const { id: teamId, channelIds } = team;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/teams/${teamId}/channels`,
        { headers }
      );
  
      if (response?.data?.error?.code == 'Forbidden') {
        const resChannel = channelIds.map((id: string) => ({
          id: id,
          status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER
        }))
        return { id: teamId, channelIds: resChannel };
      }
        
      if (response?.data?.value) {
        const result: any = {
          id: teamId,
          channelIds: []
        }
        channelIds.forEach((channelId: string) => {
          const isExist = response.data.value.findIndex((channel: any) => channel.id == channelId) > -1;
          if (isExist) {
            result.channelIds.push({id: channelId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.CONNECTED})
          } else {
            result.channelIds.push({id: channelId, status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT})
          }
        })
        return result;
      }
  
      const resChannel = channelIds.map((id: string) => ({
        id: id,
        status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT
      }))
      return { id: teamId, channelIds: resChannel };
    } catch (e: any) {
      console.log(e);
      if (e?.response?.data?.error?.code == "Forbidden" ) {
        const resChannel = channelIds.map((id: string) => ({
          id: id,
          status: AppConstant.STATUS_CONNECTION_MSTEAMS.IS_NOT_MEMBER
        }))
        return { id: teamId, channelIds: resChannel };
      }

      const resChannel = channelIds.map((id: string) => ({
        id: id,
        status: AppConstant.STATUS_CONNECTION_MSTEAMS.NOT_EXIT
      }))
      return { id: teamId, channelIds: resChannel };
    } 
  }

  public sendGroup(groupId: string, imgBase64: string[], message: any) {
    return this.getTokenForScope().pipe(
      switchMap((accessToken) => {
        return Promise.all([this.sendGroupMessage(
          accessToken,
          groupId,
          imgBase64,
          message
        )]).then((res: any) => {
          console.log(res);
          if (res?.[0].response?.data?.error?.code == "Forbidden")
            return {
              type: AppConstant.MESSAGE_TYPE.WARNING,
              message: 'Send to group chat failed.'
            };

          return {
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            message: 'Send to group chat successfull.'
          }
        }).catch((error) => {
          console.log(error);
          return {
            type: AppConstant.MESSAGE_TYPE.WARNING,
            message: 'Send to group chat failed.'
          }
        });
      }
    ))
  }
  
  public sendChannel(teamId: string, channelId: string, imgBase64: string[], message: any) {
    return this.getTokenForScope().pipe(
      switchMap((accessToken) => {
        
        return Promise.all([this.sendChannelMessage(
          accessToken,
          teamId,
          channelId,
          imgBase64,
          message
        )]).then((res: any) => {
          console.log(res);
          if (res?.[0].response?.data?.error?.code == "Forbidden")
            return {
              type: AppConstant.MESSAGE_TYPE.WARNING,
              message: 'Send to channel failed.'
            };
          return {
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            message: 'Send to channel successfull.'
          }
        }).catch((error) => {
          console.log(error);
          return {
            type: AppConstant.MESSAGE_TYPE.WARNING,
            message: 'Send to channel failed.'
          }
        });
      }
    ))
  }

  private async sendGroupMessage(accessToken: string, groupId: string, imgBase64: string[] = [], message: string) {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
      const hostedContents = imgBase64.map((base64: string, index) => {
        const str = base64.split(";base64,");
        const contentType = str[0].replace('data:', '');
        const contentBytes = str[1];
        return {
          '@microsoft.graph.temporaryId': `${index +  1}`,
          "contentType": `${contentType}`,
          "contentBytes": `${contentBytes}`,
        }}
      );

      const requestBody = {
        body: {
          content: message,
          contentType: "html"
        },
        hostedContents: hostedContents,
      };

      const response = await axios.post(
        `https://graph.microsoft.com/v1.0/chats/${groupId}/messages`,
        requestBody,
        { headers }
      );

      return response;
    } catch (error) {
      return error;
    }
  }

  private async sendChannelMessage(
    accessToken: string,
    teamId: string,
    channelId: string,
    imgBase64: string[],
    message: string = ''
  ) {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
      const hostedContents = imgBase64.map((base64: string, index) => {
        const str = base64.split(";base64,");
        const contentType = str[0].replace('data:', '');
        const contentBytes = str[1];
        return {
          '@microsoft.graph.temporaryId': `${index +  1}`,
          "contentType": `${contentType}`,
          "contentBytes": `${contentBytes}`,
        }}
      );
      const requestBody = {
        body: {
          content: message,
          contentType: "html"
        },
        hostedContents: hostedContents
      };
      const response = await axios.post(
        `https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/messages`,
        requestBody,
        { headers }
      );
      
      return response;
    } catch (error) {
      return error;
    }
  }

  getColorFromFlag(flag: string): string {
    switch(flag) {
      case 'green':
        return 'rgba(50, 184, 119, 1)';
      case 'yellow':
        return 'rgba(217, 177, 0, 1)';
      case 'red':
        return 'rgba(194, 18, 40, 1)';
      case 'grey':
        return 'rgba(201, 207, 212, 1)';
      case 'orange':
        return 'rgba(247, 160, 64, 1)';
      case 'peach':
        return 'rgba(223, 122, 90, 1)';
      case 'maroon':
        return 'rgba(153, 91, 91, 1)';
      case 'brown':
        return 'rgba(135, 79, 17, 1)';
      case 'cyan':
        return 'rgba(73, 178, 211, 1)';
      case 'pink':
        return 'rgba(221, 150, 185, 1)';
      case 'navy':
        return 'rgba(106, 133, 206, 1)';
      case 'purple':
        return 'rgba(163, 107, 230, 1)';
      case 'lime':
        return 'rgba(145, 166, 43, 1)';
      case 'blue':
      default:
        return 'rgba(111, 158, 205, 1)';
    }
  }

  public prepareTemplateMsgInitiate(payload: any, srcImgs: string[] = []) {
    const title = `${payload.alertEvent} - ${payload.alertFlag.toUpperCase()} | ${payload.well} | ${payload.wellbore} | ${payload.project}`
    const color = this.getColorFromFlag(payload.alertFlag);
    const imgSection: string = srcImgs.map((_, index) => (
      `<div style="margin-top: 20px;">
        <img style="max-width: 620px; width: auto;" src="../hostedContents/${index + 1}/$value"" />
      </div>`
    )).join("");
    

    return `<div>
        <h2 style="padding-bottom: 20px; font-size: 18px;">VIRTUAL REMOTE OPERATIONS: ALERT</h2>
        <div>
          <b style="color: ${color};">${title}</b>
        </div>
        <div>
          <b>Distribution:</b> <b style="color: ${color};">${payload.alertFlag.toUpperCase()}</b>
        </div>
        <div>
          <b>Engineer:</b> <span>${payload.userNameSave} ${payload.disciplineName}</span>
        </div>
        <div>
          <b>Remote Center:</b> <span>${payload.remoteCenter}</span>
        </div>
        <div>
          <b>Description:</b> <span>${payload.eventDescription}</span>
        </div>
        <div>
          <b>Recommendation:</b> <span>${payload.recommendation}</span>
        </div>
        ${imgSection}
    <div>`
  }
  
  public prepareTemplateMsgResolve(payload: any, srcImgs: string[] = []) {
    const title = `${payload.alertEvent} - ${payload.alertFlag.toUpperCase()} | ${payload.well} | ${payload.wellbore} | ${payload.project}`
    const color = this.getColorFromFlag(payload.alertFlag);
    const imgSection: string = srcImgs.map((base64, index) => (
      `<div style="margin-top: 20px;">
          <img style="max-width: 620px; width: auto;" src="../hostedContents/${index + 1}/$value"" />
        </div>`
    )).join("");

    return `<div>
        <h2 style="padding-bottom: 20px; font-size: 18px;">VIRTUAL REMOTE OPERATIONS: ALERT (RESOLUTION)</h2>
        <div>
          <b style="color: ${color};">${title}</b>
        </div>
        <div>
          <b>Distribution:</b> <b style="color: ${color};">${payload.alertFlag.toUpperCase()}</b>
        </div>
        <div>
          <b>Engineer:</b> <span>${payload.userNameSave} ${payload.disciplineName}</span>
        </div>
        <div>
          <b>Remote Center:</b> <span>${payload.remoteCenter}</span>
        </div>
        <div>
          <b>Resolution:</b> <span>${payload.resolutionDetail}</span>
        </div>
        <div>
          <b>Contact Method:</b> <span>${payload.contactMethod}</span>
        </div>
        ${imgSection}
    <div>`
  }
}
