import { AppConstant } from 'src/app/shared/utilities/app.constant';
import {
  Component,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { AzureAdService } from 'src/app/shared/services/azure-ad.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { FileUpload } from 'primeng/fileupload';
import { MsalService } from '@azure/msal-angular';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { UserVRO } from 'src/app/shared/models/userVRO.model';
import { environment } from 'src/environments/environment';
import {
  catchError,
  filter,
  finalize,
  take,
  takeUntil,
  takeWhile,
  BehaviorSubject
} from 'rxjs';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { PhotoCropperComponent } from 'src/app/shared/components/photo-cropper/photo-cropper.component';
import { LoaderLayOut } from 'src/app/shared/services/loaderLayOut.service';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { ModeToggleService } from 'src/lib/mode-toggle/mode-toggle.service';
import { Mode } from 'src/lib/mode-toggle/mode-toggle.model';
import { NotificationSettingComponent } from 'src/app/shared/components/notification-setting/notification-setting.component';
import jpack from '../../../../../package.json';

@Component({
  templateUrl: './home-user-information.component.html',
  selector: 'app-home-user-information',
  styleUrls: ['./home-user-information.component.scss'],
})
export class HomeUserInformationComponent extends BaseComponent {
  isLoading: boolean = false;
  isViewer: boolean = false;

  @Input() displaySidebarUserInfomation: boolean = true;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _userInfoService: UserInfoService,
    private _msalService: MsalService,
    private _azureAdService: AzureAdService,
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _router: Router,
    private _dialogService: DialogService,
    private _loaderLayOut: LoaderLayOut,
    private _confirmService: ConfirmDialogService,
    private _modeToggleService: ModeToggleService
  ) {
    super();
  }

  theme: Mode = Mode.DARK;
  status: boolean = false;
  
  toggle() {
    this.theme = this._modeToggleService.toggleMode();
    this.status = this.theme === Mode.DARK ? true : false;
  }

  // Variable of Form
  public formUserGroup!: FormGroup;

  public fieldNameKeys = {
    halId: 'halId',
    role: 'role',
    psl: 'psl',
    country: 'country',
    timezone: 'timezone',
    dialCode: 'dialCode',
    phone: 'phone',
    flag: 'flag',
    avatar: 'avatar',
  };

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',

    minlength: 'Please enter phone more 6 numbers',
    maxlength: 'Please enter phone less 12 numbers',
  };

  countries: any[] = [];
  timezones: any[] = [];
  PSLs: any[] = [];

  currentUser!: UserVRO;

  uploadProgress = 0;

  isAuthenticated = false;
  activeUser: string | undefined = 'Unknown user';
  userEmail: string | undefined = 'Unknown user';
  ramdomAvatarCharacter: string = 'AA';

  @ViewChild('uploadAvatar') uploadAvatar?: FileUpload;

  linkFileAvt: string = '';
  fileLabel: string = 'Upload file';
  showCancelButton: boolean = false;

  totalFileSizeUpload = 10000000;
  currentImageSizeUpload = 0;

  flag_OpenDialog: boolean = false;

  formLoaded?: Promise<boolean>;
  isPreviewAvatar: boolean = false;

  // Checking Cropper Avatar is open/close
  isDialogCropperVisible: boolean = false;

  // Trong dialog cropper component
  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent): void {
    if (!this.isDialogCropperVisible) {
      this.closePanel();
    }
  }

  // State of Setting Dialog
  isOpenSetting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  APPLICATION_VERSION: string = `v0.0.0`;

  onInit(): void {
    // Handle Theme Mode
    this.theme = this._modeToggleService.getCurrentMode();
    this.status = this.theme === Mode.DARK ? true : false;

    // Refresh flag reload Team Management
    this._userInfoService.requestReloadUserManagement.next(false);
    this._userInfoService.requestReloadUserPermission.next(false);

    this._userInfoService.userSubject$.pipe(
      filter((userObject: any) => !!userObject),
      takeWhile((userObject: any) => JSON.stringify(userObject) !== JSON.stringify(this.currentUser)),
    ).subscribe((currentUser) => {
      if (currentUser && currentUser.id && currentUser.role) {
        this.isViewer = currentUser.role === AppConstant.ROLES.VIEWER.label;
        this.generateUserPanel(currentUser);

        this.APPLICATION_VERSION = `${jpack.version}`;
      }
    });
  }

  generateUserPanel(userResponse: any) {
    if (userResponse) {
      this.currentUser = userResponse;
      this.currentUser.replaceCountry = this.currentUser.country
        ? this.currentUser!.country.slice(0, 2)
        : '';
      this.ramdomAvatarCharacter = this.getFirstLetter(
        this.currentUser?.displayName
      );

      this.countries = AppConstant.PHONE_COUNTRIES;
      this.timezones = AppConstant.TIME_ZONES;
      this.PSLs = AppConstant.HALLIBURTON_PSLs;

      this.countries = this.countries.map((country) => {
        return {
          ...country,
          combine_name_dial: `${country.name}  ${
            country.replace_dial_code || country.dial_code
          }`,
        };
      });

      this.linkFileAvt = this.currentUser?.avatar || '';
      
      this.buildForm();
      // Load user information successfully
      this._loaderLayOut.setLoadingStatus('userInfoAPI', true);
    }
  }

  buildForm() {
    let valueCountry = null;
    let valueTimezone = null;
    let valueFlag: any;
    let valuePhone = '';
    let valueDial: any;

    let userDailCode = this.currentUser!.phone!
      ? this.currentUser!.phone!.split(' ')[0]
      : '';

    valueFlag = this.countries.find((flag) => flag.dial_code === userDailCode);

    valueDial = this.countries.find((dial) => {
      if (dial.dial_code === userDailCode) {
        return dial;
      }
    });

    if (valueDial && this.currentUser?.phone) {
      valuePhone = this.currentUser?.phone.replace(valueDial.dial_code, '');
      valuePhone = AppHelper.UtileFunctions.replaceAllCharacter(
        valuePhone,
        ' ',
        ''
      );
    }

    if (this.currentUser?.country) {
      valueCountry = this.countries.find(
        (element) =>
          element.code ===
          (this.currentUser?.replaceCountry || this.currentUser?.country)
      );
    }
    if (this.currentUser?.timezone) {
      valueTimezone = this.timezones.find(
        (element) => element.code === this.currentUser?.timezone
      );
    }

    this.formUserGroup = this._builder.group({
      [this.fieldNameKeys.halId]: [
        { value: this.currentUser?.halId, disabled: true },
      ],
      [this.fieldNameKeys.role]: [
        { value: this.currentUser?.role, disabled: true },
      ],
      [this.fieldNameKeys.psl]: [
        {
          value:
            this.PSLs.find((psl) => this.currentUser?.psl === psl.code).name ||
            `Can't load PSL Name of code: ${this.currentUser?.psl}`,
          disabled: true,
        },
      ],
      [this.fieldNameKeys.country]: [
        { value: valueCountry, disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.timezone]: [
        { value: valueTimezone, disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.flag]: [
        { value: valueFlag, disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.dialCode]: [
        valueDial?.replace_dial_code || valueDial?.dial_code,
      ],
      [this.fieldNameKeys.phone]: [
        {
          value: Number(valuePhone) !== 0 ? Number(valuePhone) : '',
          disabled: true,
        },
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(12),
        ],
      ],

      [this.fieldNameKeys.avatar]: [{ value: '', disabled: true }],
    });
  }

  loadNameofFileAvt(linkFileAvt: string) {
    if (!linkFileAvt) {
      this.fileLabel = 'Upload file';
      return;
    } else {
      this.fileLabel = AppHelper.UtileFunctions.getFileNameFromUrl(linkFileAvt);
      this.uploadAvatar!._files = [];

      this.uploadAvatar!._files.push(
        new File([new Blob()], 'Random.jpg', {
          type: 'image/jpg',
        })
      );

      this.formUserGroup.get('avatar')?.setValue('hasValue');

      this.showCancelButton = true;
    }
  }

  enableEditing() {
    let countryControl = this.formUserGroup.get('country');
    let timezoneControl = this.formUserGroup.get('timezone');
    let phoneControl = this.formUserGroup.get('phone');
    let flagControl = this.formUserGroup.get('flag');
    let avatarControl = this.formUserGroup.get('avatar');

    countryControl?.enable();
    timezoneControl?.enable();
    phoneControl?.enable();
    flagControl?.enable();
    avatarControl?.enable();

    // set validation for phone
    this.flag_OpenDialog = true;

    this.loadNameofFileAvt(this.linkFileAvt);
  }

  disableEditing() {
    let countryControl = this.formUserGroup.get('country');
    let timezoneControl = this.formUserGroup.get('timezone');
    let phoneControl = this.formUserGroup.get('phone');
    let flagControl = this.formUserGroup.get('flag');
    let avatarControl = this.formUserGroup.get('avatar');

    countryControl?.disable();
    timezoneControl?.disable();
    phoneControl?.disable();
    flagControl?.disable();
    avatarControl?.disable();
  }

  getCroppedAvatar(file: any): void {
    // User selected invalid type file

    const errorUpload = this.uploadAvatar?.msgs;
    if (errorUpload?.length !== 0) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: AppHelper.StringFunctions.capitalizeFirstLetter(
          errorUpload![0].detail || 'Error upload'
        ),
        content: errorUpload![0].summary || 'Invalid file type',
      });
      // handle button cancel when choose invalid type
      this.onClear_avatar();
      return;
    }

    //

    this.currentImageSizeUpload = file.size;

    if (this.currentImageSizeUpload > this.totalFileSizeUpload) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Upload Document',
        content: 'Maximum total upload size of Image and Specs is 10 MB.',
      });
      return;
    }

    this.formUserGroup.get('avatar')?.setValue(file);
  }

  onClear_avatar() {
    this.uploadAvatar!.chooseLabel = 'Upload file';
    this.uploadAvatar!.showCancelButton = false;

    this.isPreviewAvatar = false;

    this.showCancelButton = false;
    this.fileLabel = 'Upload file';

    this.formUserGroup.get('avatar')?.setValue('');

    this.currentImageSizeUpload = 0;
  }

  onHide() {
    this.closePanel();
  }

  closePanel() {
    if (this.flag_OpenDialog) {
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });

    } else {
      this.disableEditing();
      this._userInfoService.changeShowUserPanelStatus(false);

      if (
        this._router.url === '/user/team-management' &&
        this._userInfoService.requestReloadUserManagement.getValue() === true
      ) {
        // pass request reload to Team Management
        this._userInfoService.reloadUserManagement$.next(true);
      } else if (
        this._router.url.includes('/user/team-management/') &&
        this._userInfoService.requestReloadUserPermission.getValue() === true
      ) {
        // pass request reload to Team Management
        this._userInfoService.reloadUserPermission$.next(true);
      } else {
        // this._loader.setLoading(false);
      }
    }
  }

  customFilterChanged(e: any) {}
  addPrefixNumber(e: any) {
    this.formUserGroup
      .get('dialCode')
      ?.setValue(e.value.replace_dial_code || e.value.dial_code);
  }

  public inputNumber_Clear_e(event: any) {
    let invalidChars = ['-', '+', 'e', 'E', '.'];

    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  public handlePasteInput(controlName: string, input: any) {
    let control = this.formUserGroup.get(controlName);

    // get paste data
    let textPaste = input.clipboardData.getData('text/plain');

    // remove AZaz -+eE from paste data
    let finalPaste = textPaste.replace(/[^0-9]+/g, '');

    control?.setValue(finalPaste);
    this.getRefresh('phone');

    input.preventDefault();
  }

  getRefresh(controlName: string) {
    const control = this.formUserGroup.get(controlName);
    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();
  }

  saveUserSetting() {
    const formData = this.formUserGroup.getRawValue();

    this.isLoading = true;

    let payload = {
      avatar: formData.avatar,
      country: formData.country.code === 'US' ? 'USA' : formData.country.code,
      timezone: formData.timezone.code,
      phone: `${formData.flag.dial_code || formData.dialCode} ${
        formData.phone
      }`,
    };

    this._userInfoService.updateUserProfile(payload)
      .pipe(
        finalize(() => (this.isLoading = false)),
        catchError(AppHelper.UtileFunctions.handleError),
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe({
        next: (updateResponse: any) => {
          if (updateResponse) {
            this._userInfoService.setUserInfor(updateResponse.data);

            this.generateUserPanel(updateResponse.data);
            // Add flag to refrest if we in /user/team-management
            this.flag_OpenDialog = false;
            this._userInfoService.requestReloadUserManagement.next(true);
            this._userInfoService.requestReloadUserPermission.next(true);
            this.destroy$.complete();
            this.closePanel();

            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'User Preferences',
              content: `Update Profile User: ${this.currentUser?.mail} successfully!`,
            });

            // this.formLoaded = Promise.resolve(false);
            this.flag_OpenDialog = false;
            this.closePanel();
          }
        },
        error: (error: any) => {
          console.error(error);
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'User Preferences',
            content: error?.message || error,
          });
        },
      });
  }

  logout_Azure() {
    const currentAccount = this._msalService.instance.getActiveAccount();

    this._azureAdService.isUserLoggedIn.next(false);
    this._azureAdService.userNameLoggedIn.next(undefined);
    this._msalService.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: environment.logoutUrl,
    });
  }

  getFirstLetter(userName: string | undefined): string {
    if (!userName) return 'Unknown User Name';

    let arrySplitName = userName.trim().split(' ');
    let userNameLength = arrySplitName.length;

    let firstLetter = arrySplitName[0].charAt(0);
    let secondLetter = arrySplitName[userNameLength - 1].charAt(0);

    return (firstLetter + secondLetter).toLocaleUpperCase() || userName;
  }

  // this function using for normal errors messages
  public isInvalidControl(controlName: string): boolean {
    const control = this.formUserGroup.get(controlName);

    //

    if (!control) {
      return false;
    }
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const controlValue = this.formUserGroup.get(controlName)?.value;

    const errorObj = this.formUserGroup.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }

    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);

    // get message
    return listMsg;
  }

  showCropper(event: any): void {
    if (event.currentFiles[0]) {
      this.uploadAvatar!.showCancelButton = true;

      // pipe letter here
      this.uploadAvatar!.chooseLabel =
        AppHelper.StringFunctions.shortenFileName(
          event.currentFiles[0].name,
          8,
          5
        );

      this.fileLabel = event.currentFiles[0].name;

      const ref = this._dialogService.open(PhotoCropperComponent, {
        header: 'Edit Photo',
        width: '30.25rem',
        data: {
          file: event.currentFiles[0],
          isRound: true,
          ratio: 1 / 1,
        },
        closable: true,
        closeOnEscape: true,
      });

      if (ref) {
        this.isDialogCropperVisible = true;
      }

      ref.onClose.subscribe({
        next: (res: any) => {
          if (res) {
            this.isPreviewAvatar = true;
            this.getCroppedAvatar(res);
          } else {
            this.uploadAvatar!._files = [];
            this.uploadAvatar!.showCancelButton = false;
            this.uploadAvatar!.chooseLabel = 'Upload file';
            this.uploadAvatar?.cd.markForCheck();
          }
          // Update isDialogCropperVisible
          this.isDialogCropperVisible = false;
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Invalid File Type`,
        content: `Invalid file type. Allowed file types: .png, .jpg, .jpeg`,
      });
    }
  }

  openNotiSetting() {
    this.isOpenSetting$.next(true);
    const ref = this._dialogService.open(NotificationSettingComponent, {
      header: `Manage Notifications`,
      data: {
        userId: this.currentUser.id
      },
      styleClass: 'style-class-dialog-custom'
    });

    ref.onClose
      .subscribe({
        next: (res: any) => this.isOpenSetting$.next(false),
        error: (error) => console.log(error),
      });
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.flag_OpenDialog = false;
            this.closePanel();
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  onDestroy(): void {}
}


